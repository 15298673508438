@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

@import url("./app/styles/_fonts.css");
@import url("./app/styles/_buttons.css");
@import url("./app/styles/_forms.css");
@import url("./app/styles/_titles.css");
@import url("./app/styles/_tables.css");

*,
*::before,
*::after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  font-size: 16px;

  @media (min-width: 768px) {
    font-size: 1.111vw;
  }
}

body {
  background-color: #fff;
  margin: 0;
  height: 100%;

  overscroll-behavior: none;
  -webkit-overflow-scrolling: touch;

  @apply font-primary text-text-primary dark:bg-black dark:text-gray-25;
}

@layer components {
  .container {
    @apply mx-auto max-w-full px-[6.25rem] max-md:px-4;
  }

  .main-container {
    @apply mx-auto max-w-[58.75rem] px-9 max-md:max-w-full max-md:px-4;
  }

  .landing-container {
    @apply mx-auto max-w-[107.5rem] px-[6.25rem] max-md:max-w-full max-md:px-4;
  }

  .box {
    @apply rounded-2xl border border-gray-100 bg-gray-25 px-4 py-5 dark:border-slate-700 dark:bg-slate-800;
  }

  .dot {
    @apply inline-block h-2 w-2 rounded-full bg-text-pale;
  }

  .divider {
    @apply h-[0.0625rem] border-none bg-gray-100 dark:bg-gray-700;
  }

  .admin-body {
    @apply flex flex-col space-y-6;
  }

  .admin-value-row-wrapper {
    @apply flex flex-col gap-y-2 text-sm font-medium;
  }

  .admin-value-row {
    @apply flex w-full items-center justify-between gap-x-2 rounded-lg bg-gray-100 p-3 text-sm font-medium dark:bg-slate-700;
  }

  .custom-tooltip {
    @apply max-w-[12rem] rounded-main bg-accent px-2 py-1 text-center text-sm text-text-secondary;
  }

  .bg-gradient {
    background: conic-gradient(from 39.91deg at 50% 50%, #317544 0deg, #2bc157 360deg);
  }

  /* For ng-bootstrap dropdown */
  .dropdown {
    @apply relative block;
  }
  .dropdown > .dropdown-toggle {
    @apply cursor-pointer;
  }
  .dropdown > .dropdown-menu {
    @apply hidden w-fit min-w-[6.3rem] overflow-hidden rounded-xl border border-gray-100 bg-white shadow dark:border-slate-700 dark:bg-slate-800;
  }
  .dropdown .dropdown-item {
    @apply flex w-full cursor-pointer items-center gap-x-2 whitespace-nowrap px-4 py-2 text-sm font-semibold text-text-secondary hover:bg-gray-100 hover:text-text-primary disabled:cursor-not-allowed disabled:opacity-60 dark:hover:bg-slate-700 dark:hover:text-white;
  }
  .dropdown > .dropdown-menu.show {
    @apply z-10 block;
  }

  /* Designed toggle */
  .dropdown-base-toggle {
    @apply flex items-center justify-between whitespace-nowrap rounded-xl border border-gray-100 px-4 py-2.5 text-start text-sm font-semibold text-text-secondary focus:outline-none dark:border-slate-700;
  }
  .dropdown.show .dropdown-base-toggle {
    @apply border-green-400;
  }

  .line-gradient-border {
    border-image: radial-gradient(circle, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0)) 1;
    border-width: 1px;
    border-style: solid;
    border-image-slice: 1;
  }
}

@layer utilities {
  /* Scrollbar */
  ::-webkit-scrollbar {
    @apply h-2 w-2;
  }

  ::-webkit-scrollbar-track {
    @apply rounded-full bg-gray-50;
  }

  ::-webkit-scrollbar-thumb {
    @apply rounded-full bg-gray-300;
  }

  .dark ::-webkit-scrollbar-track {
    @apply bg-slate-800;
  }

  .dark ::-webkit-scrollbar-thumb {
    @apply bg-slate-500;
  }
}

body.modal-open {
  padding-right: 0 !important;
}

.modal-backdrop {
  @apply fixed inset-0 !z-[100] bg-black/70;
}

.modal-backdrop.show {
  display: block;
}

.modal {
  @apply fixed inset-0 z-[105] hidden items-center justify-center overflow-auto md:justify-center;
}

.modal.show {
  display: flex;
}

.modal-dialog {
  @apply relative w-full min-w-[18.75rem] overflow-y-hidden rounded-3xl bg-gray-25 dark:border dark:border-slate-700 dark:bg-slate-800 max-md:mx-4 md:w-auto md:min-w-[32rem] md:rounded-[2rem];
}

.modal-dialog_full-content {
  @apply max-md:mx-0 max-md:w-full max-md:rounded-none max-md:p-0;
}

.modal-dialog_full-content > .modal-content {
  @apply max-md:h-screen max-md:max-h-[100dvh] max-md:p-4;
}

.modal-content {
  @apply relative max-h-[90dvh] overflow-y-auto p-8 max-md:p-6;
}

/* Datepicker here, because container is body */
.ngb-dp-header {
  @apply mb-3 !p-0;
}

.ngb-dp-arrow {
  @apply !h-6 !w-6 shrink-0;
}

.ngb-dp-arrow-btn {
  @apply !m-0 h-full w-full !bg-transparent !p-0 hover:!bg-transparent focus:outline-none;
}

.ngb-dp-arrow-btn[disabled] {
  @apply pointer-events-none opacity-50;
}

.ngb-dp-arrow-btn span {
  @apply border-text-secondary hover:border-black dark:hover:border-white;
}

ngb-datepicker-navigation-select > .form-select {
  @apply bg-transparent !p-0 !text-base !font-semibold;
}

ngb-datepicker-navigation-select > .form-select > option {
  @apply bg-white dark:bg-slate-800;
}
