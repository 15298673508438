.btn-base {
  @apply flex cursor-pointer items-center justify-center rounded-xl transition-colors;
}

.btn-base:disabled {
  @apply cursor-not-allowed;
}

.btn-primary {
  @apply btn-base;
  @apply !bg-green-400 font-bold text-white hover:!bg-green-300 disabled:!bg-gray-300 disabled:text-text-tertiary dark:disabled:!bg-slate-500 dark:disabled:text-[#5d6762];
}

.btn-primary-dark {
  @apply btn-base;
  @apply border-gray-900 !bg-gray-900 font-bold text-white hover:border-green-400 hover:!bg-green-300 active:outline active:outline-black dark:!bg-white dark:text-black dark:hover:!bg-green-300;
}

.btn-primary-danger {
  @apply btn-base;
  @apply !bg-red-100 font-bold text-red-600 hover:!bg-red-500 hover:text-white disabled:!bg-gray-300 disabled:text-text-tertiary dark:disabled:!bg-slate-500 dark:disabled:text-[#5d6762];
}

.btn-outline {
  @apply btn-base;
  @apply border-green-400 !bg-transparent text-green-400 hover:!bg-green-300 hover:text-white active:outline active:outline-black;
}

.btn-outline-danger {
  @apply btn-base;
  @apply border-red-400 !bg-transparent text-red-400;
}

.btn-small {
  @apply px-4 py-2;
}

.btn-medium {
  @apply px-6 py-3 text-base max-md:text-xs;
}

.btn-large {
  @apply px-6 py-[1.125rem];
}

.btn-flat {
  @apply flex items-center justify-center gap-x-2 border-0 bg-transparent p-0 text-base text-text-secondary transition-colors hover:text-black disabled:cursor-not-allowed disabled:text-text-secondary dark:hover:text-white;
}

.btn-flat_danger {
  @apply text-red-500 hover:text-red-600;
}

.btn-text {
  @apply btn-base;
  @apply border-0 bg-transparent text-sm font-semibold text-green-400 hover:underline;
}

.btn-text-danger {
  @apply btn-base;
  @apply border-0 bg-transparent text-sm font-semibold text-red-500 hover:underline;
}

.btn-secondary {
  @apply btn-base;
  @apply bg-gray-100 font-semibold text-text-secondary hover:text-text-primary disabled:bg-gray-300 dark:bg-slate-700 dark:hover:text-white dark:disabled:bg-slate-500;
}
