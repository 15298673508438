/* Form */
.customForm {
  @apply grid grid-rows-1 gap-4 rounded-[0.625rem] border-[0.0625rem] border-stroke-color bg-box-background px-[1.875rem] py-[2.125rem];
}

/* Input */
.customInput {
  position: relative;
}

.customInput > p {
  @apply mb-2 text-sm font-semibold;
}

.customInput__inputWrapper {
  @apply relative;
}

.customInput input {
  @apply w-full rounded-xl border border-gray-100 bg-gray-25 px-4 py-5 text-sm font-semibold text-text-primary placeholder:text-text-tertiary focus:outline-0 dark:border-slate-700 dark:bg-slate-800 dark:text-white;
}

.customInput input[type="file"] {
  display: none;
}

.customInput input[type="tel"] {
  padding-left: 3.5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.customInput input[type="number"] {
  -moz-appearance: textfield;
}

.customInput__phonePlus {
  top: 2.55rem;
  left: 1rem;
  @apply absolute font-semibold text-text-secondary dark:text-white;
}

.customInput input.is-invalid {
  @apply border-red-500 bg-red-500/10;
}

.customInput input.is-valid {
  @apply border-green-400;
}

.customInput__error {
  @apply mt-2 block text-sm font-semibold text-red-500;
}

/* File selection */
.customInput__file {
  @apply flex items-center justify-between px-4 py-2 pr-10;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='green' stroke-width='1' stroke-dasharray='8' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e");
  background-size: contain;
  background-repeat: no-repeat;
}

.customInput__file > p {
  @apply truncate text-sm text-text-pale underline;
}

.customInput__file > button {
  @apply w-6 text-5xl leading-6 text-text-pale;
}

.customInput__file_error {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='red' stroke-width='1' stroke-dasharray='8' stroke-dashoffset='1' stroke-linecap='square'/%3e%3c/svg%3e");
}

.customInput__comment {
  @apply text-sm text-text-pale;
}

/* Checkbox */
.customCheckbox {
  @apply absolute -z-10 opacity-0;
}

.customCheckbox + label {
  display: inline-flex;
  align-items: flex-start;
  user-select: none;
  @apply py-2;
}
.customCheckbox + label::before {
  @apply m-1 mr-2 inline-block h-4 w-4 shrink-0 grow-0 cursor-pointer rounded-[0.25rem] border-2 border-slate-200 bg-transparent;
  content: "";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.customCheckbox:checked + label::before {
  @apply border-accent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230da859' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}
.customCheckbox + label > p {
  @apply text-gray-900;
}

/* Search input in admin panel */
.searchInput {
  @apply relative w-[19rem];
}

.searchInput > input {
  @apply min-h-[2.75rem] w-full rounded-2xl border border-gray-100 bg-white px-4 py-3 pr-10 text-base font-medium text-black placeholder:text-slate-400 focus:outline-0 dark:border-slate-700 dark:bg-slate-800 dark:text-white dark:placeholder:text-slate-400;
}

.searchInput__btn {
  position: absolute;
  right: 1rem;
  top: 0.75rem;
  width: 1.5rem;
  height: 1.5rem;
}

input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s !important;
}
