@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-Black.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-Black.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Black.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Black.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-ExtraBold.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraBold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraBold.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraBold.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-Bold.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-Bold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Bold.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Bold.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-SemiBold.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-SemiBold.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-SemiBold.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-SemiBold.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-Medium.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-Medium.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Medium.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Medium.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-Regular.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-Regular.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Regular.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Regular.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-Light.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-Light.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Light.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Light.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-ExtraLight.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraLight.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraLight.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraLight.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Encode Sans Semi Expanded";
  src: url("../../assets/fonts/EncodeSansSemiExpanded-Thin.eot");
  src:
    url("../../assets/fonts/EncodeSansSemiExpanded-Thin.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Thin.woff2") format("woff2"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Thin.woff") format("woff"),
    url("../../assets/fonts/EncodeSansSemiExpanded-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Pnfont */
@font-face {
  font-family: "pnfont";
  src: url("../../assets/fonts/pnfont-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "pnfont";
  src: url("../../assets/fonts/pnfont-Medium.ttf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "pnfont";
  src: url("../../assets/fonts/pnfont-Semibold.ttf");
  font-weight: 600;
  font-style: normal;
}
