/* Pagination */
ngb-pagination > .pagination {
  @apply flex items-center justify-center gap-2;
}

ngb-pagination .page-item {
  @apply h-9 w-9 rounded-lg bg-red-50 text-sm font-medium text-text-secondary hover:bg-green-400 hover:text-white dark:bg-slate-800 dark:text-white hover:dark:bg-green-400;
}

ngb-pagination .page-item > a {
  @apply flex h-full w-full items-center justify-center;
}

ngb-pagination .page-item.active {
  @apply bg-green-400 text-white;
}

ngb-pagination .page-item.disabled {
  @apply pointer-events-none bg-transparent opacity-80;
}

ngb-pagination .page-item > a[aria-label="First"],
ngb-pagination .page-item > a[aria-label="Previous"],
ngb-pagination .page-item > a[aria-label="Next"],
ngb-pagination .page-item > a[aria-label="Last"] {
  @apply rounded-lg bg-transparent text-text-secondary hover:bg-green-400 hover:text-white dark:bg-slate-800 dark:text-white hover:dark:bg-green-400;
}

ngb-pagination a[aria-label="Previous"] span {
  @apply hidden;
}

ngb-pagination a[aria-label="Previous"]::before {
  content: "";
  @apply bg-[url('/assets/icons/chevron-down.svg')] bg-cover bg-center dark:invert;
  transform: rotate(90deg);
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.8;
}

ngb-pagination a[aria-label="Next"] span {
  @apply hidden;
}

ngb-pagination a[aria-label="Next"]::before {
  content: "";
  @apply bg-[url('/assets/icons/chevron-down.svg')] bg-cover bg-center dark:invert;
  transform: rotate(-90deg);
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.8;
}

/* Table */
.adminTable {
  @apply w-full;
}

.adminTable > thead th {
  @apply px-5 py-3 text-left text-xs font-semibold text-text-secondary first:pl-6 last:pr-6;
}

.adminTable > tbody tr {
  @apply border-t border-gray-50 dark:border-slate-700;
}

.adminTable > tbody td {
  @apply px-5 py-5 text-left text-sm font-semibold first:pl-6 last:pr-6;
}

.adminTable > tbody td > div {
  @apply flex items-center justify-start gap-2;
}

.adminTable > tbody td span {
  @apply truncate;
}
